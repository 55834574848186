import React, { useEffect, useState } from 'react'

import Layout from '../../components/Layout'
import StoreRoll from '../../components/StoreRoll'
import { useMediaQuery } from '../../hooks/useMediaQuery'

const StoreIndexPage = () => {
  const isDesktop = useMediaQuery('(min-width: 769px)')
  const [backgroundPosition, setBackgroundPosition] = useState('')

  useEffect(() => {
    setBackgroundPosition(isDesktop ? 'center 700px' : 'center')
  }, [isDesktop])
  return (
    <Layout>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url('/img/store-background.jpg')`,
          backgroundPosition: `${backgroundPosition}`,
          backgroundAttachment: `fixed`,
          ...(!isDesktop && { backgroundRepeat: 'no-repeat' }),
        }}
      />
      <section className="section">
        <div className="container">
          <div className="content">
            <StoreRoll />
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default StoreIndexPage
